$text-normal: #0E3253;
$text-light: #7F89A8;

$white-color: #FFFFFF;

$primary-darker: #104F8E;
$primary-dark: #016AA3;
$primary-normal: #03B6E3;
$primary-light: #95DDF3;
$primary-lighter: #EDFAFD;

$success-darker: #094546;
$success-dark: #19695B;
$success-normal: #32936F;
$success-light: #9AE0B8;
$success-lighter: #D8F9E0;

$warning-darker: #742F04;
$warning-dark: #AE580B;
$warning-normal: #F39216;
$warning-light: #FBCB72;
$warning-lighter: #FEF2D0;

$error-darker: #72082D;
$error-dark: #9A0F34;
$error-normal: #D61F3E;
$error-light: #F58B8A;
$error-lighter: #FEE7E7;

$gray-darker: #303030;
$gray-dark: #474747;
$gray-normal: #909090;
$gray-light: #D7D7D7;
$gray-lighter: #F8F8F8;

:export {
  // Base
  white: $white-color;
  // Success
  successLigther: $success-lighter;
  // Primary
  primaryDarker: $primary-darker;
  primaryDark: $primary-dark;
  primaryNormal: $primary-normal;
  primaryLighter: $primary-lighter;
  // Warning
  warningLight: $warning-light;
  warningLighter: $warning-lighter;
  // Error
  errorLight: $error-light;
  errorLighter: $error-lighter;
  // Gray
  grayNormal: $gray-normal;
  grayLight: $gray-light;
  grayLighter: $gray-lighter;
}
