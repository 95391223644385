@import "./colors";
@import "./shadows";

.br-0 {
  border-radius: 0;
}

.br-1 {
  border-radius: 4px;
}

.br-2 {
  border-radius: 8px;
}

.typography {
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
  }

  .text-color-light {
    color: $text-light;
  }

  .text-super-small {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  ul {
    padding-left: 0;
    padding-inline-start: 0;
    list-style: none;

    li::before {
      content: "";
      display: inline-block;
      height: 1rem;
      width: 16px;
      background: url("../../svg/list-bullet.svg") 0 no-repeat;
      position: absolute;
      top: 3px;
      margin-right: -16px;
      left: 0;
    }

    li {
      line-height: 1.5rem;
      padding-left: 24px;
      position: relative;
    }
  }

  .card-body {
    padding: 24px;
  }

  .btn-link {
    text-decoration: none;
    color: $primary-normal;
    &:hover {
      color: $primary-dark;
    }
  }

  .table {
    th {
      border-top: none;
      border-bottom: 1px solid $gray-light;
    }
    td {
      border-top-color: $gray-lighter !important;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}

.tax-icon-pos-fix {
  position: relative;
  top: -2px;
  right: 4px;
  width: 16px;
  height: 16px;
}

.flex-none {
  flex: none;
}
