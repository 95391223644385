$shadow-sm:         0px 4px 16px rgba(0, 0, 0, 0.06), 0px 1px 6px rgba(0, 0, 0, 0.04);
$shadow-lg:         0px 1px 6px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.04), 0px 8px 20px rgba(0, 0, 0, 0.04);

.bs-none {
  box-shadow: none;
}

.bs-small {
  box-shadow: $shadow-sm;
}

.bs-large {
  box-shadow: $shadow-lg;
}
