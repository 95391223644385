
.flag-icon {
  line-height: 0.9rem;
}

.flag-icon-globe {
  padding-left: 0.2rem;
  display: contents;

  &::after {
    content: '🌍';
  }
}
